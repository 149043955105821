/** @jsxImportSource @emotion/react */
import { Button } from "react-bootstrap";

interface Props {
  hasLinkButton: boolean;
  linkTitle?: string;
  linkUrl?: string;
  setIsSalesPolicyOpen?: (isSalesPolicyOpen: boolean) => void;
}

const SalesPolicy = ({ hasLinkButton, linkTitle, linkUrl, setIsSalesPolicyOpen }: Props) => {
  const baseCode = process.env.REACT_APP_CODE;
  
  return (
    <>
      <div className="scrollWrap">
        {baseCode === 'financial-agency' && (
          <div className="headArea">
            <p className="aR">C202085</p>
            <p>お客様各位</p>
            <p className="aR">株式会社フィナンシャル・エージェンシー</p>
          </div>
        )}
        {baseCode === 'ssnb' && (
          <div className="headArea">
            <p className="aR">C202085</p>
            <p>お客様各位</p>
            <p className="aR">住信ＳＢＩネット銀行株式会社</p>
          </div>
        )}
        {baseCode === 'test' && (
          <div className="headArea">
            <p className="aR">CXXXXXX</p>
            <p>お客様各位</p>
            <p className="aR">株式会社テスト</p>
          </div>
        )}
        <h2>保険商品のご案内にあたって</h2>
        <ol>
          <li>
            当社の販売方針（特定の保険会社を推奨する理由）
            <ol className="likeSub">
              <li>(1) 生命保険(損害保険の第三分野を含む取り扱い)<br />
                保デジWEBをご利用いただいた方には下記基準に沿って特定の保険会社を推奨します。<br />
                ただし、インターネット申込を希望される場合は後述する推奨保険会社のうち、当社で開発した保険見積システム(以下「保デジWEB」といいます)に表示可能かつインターネット申込ができる保険会社を推奨保険会社とします。<br />
                お客様が希望された保険会社に加え、保デジWEBに搭載できる保険会社のうち、当社の営業活動や営業品質向上及び顧客フォロー等の費用に対して経済的支援の手厚い保険会社の中から予め10社(通販は8社)を選定しております。<br />
                但し、①経済的支援が特に手厚い場合、又は②取引条件維持のために特定の保険会社と定めた販売目標の達成に向けて販売を行う場合、上記保険会社のうち、いずれか特定の保険会社の選定を行います。<br />
                特定の保険会社の選定は当社の規程に従い、当社として意思決定を行いますが、後者②の場合、販売促進目標の達成状況に応じて、下記の推奨保険会社のうち、当社の方針として、特定の保険会社を特に推奨することもあります。
              </li>
              <li>
                  (1)-1.認知症保険<br />認知症保険を取り扱っている保険会社のうち、当社の営業活動や営業品質向上及び顧客フォロー等の費用に対して経済的支援の手厚い保険会社の中から予め1社を選定することとしております。
              </li>
              <li>
                  (2) 損害保険(インターネット以外での損害保険をご希望される場合)<br />
                  当社の営業活動や営業品質向上及び顧客フォロー等の費用に対して経済的支援の手厚い保険会社の中から予め1社を選定しております。ただし、この推奨保険会社以外の保険会社の説明を希望されるお客様については、共同募集や挙績について特に協力する保険会社の中から別途4社を選定しております。
              </li>
            </ol>
          </li>
          <li>
            推奨保険会社
            <ol className="likeSub">
              <li>
                (1) 生命保険(損害保険の第三分野を含む)
                <table>
                  <tr>
                   <td>SOMPOひまわり生命保険株式会社</td>
                   <td>FWD生命保険株式会社</td>
                  </tr>
                  <tr>
                   <td>メットライフ生命保険株式会社(対面のみ)</td>
                   <td>オリックス生命保険株式会社</td>
                  </tr>
                  <tr>
                   <td>なないろ生命保険株式会社</td>
                   <td>東京海上日動あんしん生命保険株式会社(対面のみ)</td>
                  </tr>
                  <tr>
                   <td>ネオファースト生命保険株式会社</td>
                   <td>はなさく生命保険株式会社</td>
                  </tr>
                  <tr>
                   <td>プラス少額短期保険株式会社</td>
                   <td>SBIいきいき少額短期保険株式会社</td>
                  </tr>
                </table>
              </li>
              <li>
                (1)-1.認知症保険
                <table className="half">
                  <tr>
                   <td>SOMPOひまわり生命保険株式会社</td>
                  </tr>
                </table>
              </li>
              <li>
                (2) 損害保険(インターネット以外での損害保険をご希望される場合や、下記(3)以外の火災保険や自動車保険等)
                <table className="triple">
                  <colgroup>
                    <col span={3} className="tripleCols" />
                  </colgroup>
                  <tr>
                    <td></td>
                    <td colSpan={2}>損害保険ジャパン株式会社</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>
                      上記保険会社以外を希望された場合
                    </td>
                    <td>東京海上日動火災保険株式会社</td>
                    <td>あいおいニッセイ同和損害保険株式会社</td>
                  </tr>
                  <tr>
                    <td>三井住友海上火災保険株式会社</td>
                    <td>ソニー損害保険株式会社</td>
                  </tr>
                </table>
              </li>
              <li>
                (3) 損害保険(会員サービスやアプリ等のプロモーションやキャンペーン等を経由して販売する自転車保険・傷害保険・ペット保険など)
                <table className="half">
                  <tr>
                   <td>au損害保険株式会社</td>
                  </tr>
                </table>
              </li>
            </ol>
          </li>
          <li>
            特定の保険会社、保険商品をご希望の場合<br />
            上記2.の推奨保険会社以外の特定の保険会社、保険商品のご希望がある場合につきましては、当社へその旨お申し付けください。取り扱いの担当者より当社取扱商品の範囲内で、ご意向に沿った保険会社、保険商品をご提案させていただきます。<br />
            また、通販専用プラン、対面専用プランもございます。ご希望があれば、通販・対面とご対応方法をお選びいただくこともできますのでお申し付けください。
          </li>
          <li>
            当社の取扱保険会社<br />
            当社は多くのお客様のご要望にお応えできるように生命保険・損害保険において複数の保険会社と代理店委託契約を締結しております。取扱保険会社はこちらをご参照ください。<br />
            <a href="https://www.financial-agency.com/" target="_blank" rel="noreferrer noopener">https://www.financial-agency.com/</a><br />
            <br />
            当社からご案内する保険のうち一部の保険会社を除き第二分野(損害保険等)は保険契約締結の代理権を有しておりますが、第一分野・第三分野の保険商品(死亡保険・医療保険・ガン保険等)及び一部の第二分野(損害保険等)と少額短期保険は保険会社からの委託を受けてお客様と引受保険会社の保険契約締結の媒介を行います。告知受領権や保険契約の締結の代理権はありません。
          </li>
        </ol>
        <p>保デジ WEB</p>
      </div>
      {hasLinkButton && (
        <div className="linkButton">
          <Button
            href={linkUrl}
            target="_blank"
            className="netBtn buttonIcon buttonRadius"
            variant="orange"
            onClick={() => {
              if (setIsSalesPolicyOpen !== undefined){
                setIsSalesPolicyOpen(false);
              }
            }}
          >
            {linkTitle}
            <span className="sub">保険会社サイトへ</span>
          </Button>
        </div>
      )}
    </>
  );
};
export default SalesPolicy;
